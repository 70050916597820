<script setup lang="ts">
defineProps({
  property: {
    type: Object as PropType<Sanity.Default.Schema.Properties>,
    required: true,
  },
})

const { isSold, isUnderOffer, isLetAgreed } = useStatus()
</script>

<template>
  <div
    v-if="isSold(property) || isUnderOffer(property) || isLetAgreed(property)"
    class="absolute top-0 left-0 triangle"
  />
  <div class="text-white uppercase font-semibold absolute top-0 -rotate-45 w-[85px] h-[85px] text-center">
    <div v-if="isSold(property)" class="text-2xl leading-none pt-7">
      Sold
    </div>
    <div v-if="isUnderOffer(property)" class="text-md leading-none pt-5">
      Under Offer
    </div>
    <div v-if="isLetAgreed(property)" class="text-lg leading-none pt-3">
      Let Agreed
    </div>
  </div>
</template>

<style scoped>
.triangle {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 108px 108px 0 0;
    border-color: #628e64 transparent transparent transparent;
}
</style>
